<template>
  <div class="text-left SignUpSignIn" style="overflow-x: hidden">
    <div v-if="isLoading">
      <LoaderComp />
    </div>

    <div v-else class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div class="">
          <router-link to="/">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell Product Recommendation Quiz Logo"
              title="Product recommendation quiz"
              class="img-fluid"
              height="40"
              width="180"
            />
          </router-link>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 90%"
          v-if="defaultView == 'Invitation'"
        >
          <div class="TitleDiv">
            <h1 class="title">Invitation</h1>
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 90%"
          v-if="defaultView == 'Workspaces'"
        >
          <div class="TitleDiv">
            <h1 class="title">Workspaces</h1>
          </div>
        </div>
      </div>

      <div
        class="d-none d-lg-block col-lg-5 objectSection lazyload"
        :data-bg="'~/assets/new-website/SignUpNewBg.svg'"
      >
        <LazyImage
          src="https://images.quizell.com/website/SignUpNewBg.svg"
          :title="`Quizell login`"
          :alt="`Quizell login`"
          style="height: 100%; width: 100%"
          :loading="'lazy'"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Invite-Register",
  props: ["id"],
  components: {
    LoaderComp: () =>
      import(
        /* webpackChunkName: "LoaderComp" */ "../../customize/component/LoaderComp.vue"
      ),
  },

  data() {
    return {
      query: { token: "" },
      isLoading: false,
      defaultView: "Invitation",
    };
  },

  methods: {
    getActiveBtn(pathName) {
      return this.$route.name == pathName ? "ActiveLink" : null;
    },
    getActiveRoute(pathName) {
      return this.$route.name == pathName ? true : false;
    },
    async checkInviteToken() {
      try {
        this.isLoading = true;
        let data = {
          token: this.id,
        };

        const response = await this.$store.dispatch("fetchData", {
          axiosMethod: "post",
          resource: "rolesPermissions",
          method: "checkInviteToken",
          params: data,
        });
        if (response.status) {
          let { show_register_page, show_workspaces, invited, invited_by } =
            response.data;
          if (show_register_page) {
            this.defaultView = "Invitation";
            this.$toasted.show(
              "Invitation accepted successfully! Please create a Quizell Account.",
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
            this.$router.push({
              name: "invitation-sign-up",
              query: {
                invited: invited,
                invited_by: invited_by,
              },
            });
          }
          if (show_workspaces) {
            this.defaultView = "Workspaces";
            this.$toasted.show(
              "Invitation accepted successfully! Please login to your Quizell Account.",
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
            this.$router.push({ name: "login", query: { invitedLogin: true } });
          }
        } else {
          this.$toasted.show("Token is expired or Invalid. Please try again!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        this.$toasted.show("Error Occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {},
  created() {
    if (!this.id) {
      window.open("/", "_self");
    } else {
      this.checkInviteToken();
    }
  },
};
</script>
<style>
.NewFormInput {
  border: 1px solid #dedede;
  border-radius: 12px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  padding: 14px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.NewFormInput input {
  background: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.NewFormInput input:focus {
  border: none;
  outline: none;
}

.NewFormInput input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  text-align: start;

  color: #d1d1d1;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}

.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e !important;
}
</style>
<style scoped>
.h2 {
  font-family: "Poppins";

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #161e34;
}

.SignUpSignIn .MainSection {
  padding: 1% 5%;
  /* height:100%; */
  min-height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
}

.MainSection .title {
  font-family: "Poppins";
  /* font-family: 'Gilroy'; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38.82px;
  text-align: center;
  color: #161e34;
}

.MainSection .titleDesc h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #161e34;
  padding-bottom: 20px;
}

.objectSection {
  background-color: #f6edff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}

/* Btn Group Styles */

.BtnGroup {
  border-radius: 12px;
  background: #f0f0f2;
  padding: 5px;
  max-width: 500px;
}

.BtnGroup .linkBtn {
  display: flex;
  padding: 12px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #7a7a7a;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BtnGroup .linkBtn:hover {
  text-decoration: none;
  color: inherit;
}

.BtnGroup .linkBtn.ActiveLink {
  background: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
}

.continueDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #969696;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.continueDiv .lineElement {
  height: 1px;
  flex-grow: 1;
  background: #969696;
}

.continueDiv .textElement {
  flex: 1;
}

.SignUpPlatformImg {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.FormDiv {
  width: 50%;
}

@media (max-width: 768px) {
  .FormDiv {
    width: 100%;
  }
}

@media (max-width: 1500px) {
  .FormDiv {
    width: 70%;
  }
}
</style>
